import React from 'react';
import { useIntl } from 'react-intl';
import BaseLayout from 'layout/BaseLayout';
import {
  ChangelogContainer,
  List,
  ListItem,
  CategoryTitle,
  Title,
  VersionTitle,
  TypeTitle,
  TypeItem,
} from './ChangelogPage.style';

interface ChangeItem {
  category: string;
  items: string[];
}

interface ChangeLog {
  ajout: ChangeItem[];
  suppression: ChangeItem[];
  modification: ChangeItem[];
}

interface ChangelogData {
  version: string;
  changes: Partial<ChangeLog>;
}

const changelogData: ChangelogData[] = [
  {
    version: '3.0.0',
    changes: {
      ajout: [
        {
          category: 'Secure',
          items: [
            'secure-vuln',
            'secure-git',
            'iam-lifecycle',
            'iam-sso',
            'iam-account-protection',
            'environment-isolated',
            'secret-rotation',
          ],
        },
      ],
      suppression: [
        {
          category: 'Secure',
          items: [
            'secure-deploy',
            'secure-output',
            'secure-vuln-tool',
            'infrastructure-mfa',
            'git-repository-mfa',
            'minimum-rights',
            'onboarding-offboarding-process',
            'no-privileges-elevation',
            'encrypted-critical-data',
          ],
        },
      ],
      modification: [
        {
          category: 'Secure',
          items: ['secure-tools', 'secure-intrusion'],
        },
      ],
    },
  },
  {
    version: '2.0.0',
    changes: {
      ajout: [
        {
          category: 'Operable',
          items: ['dry'],
        },
      ],
      modification: [
        {
          category: 'Operable',
          items: ['linting', 'standard', 'module'],
        },
      ],
    },
  },
];

const ChangelogPage: React.FC = () => {
  const intl = useIntl();
  return (
    <BaseLayout title="CHANGELOG">
      <ChangelogContainer>
        <Title>CHANGELOG</Title>
        {changelogData.map((log) => (
          <div key={log.version}>
            <VersionTitle>{log.version}</VersionTitle>
            <List>
              {Object.entries(log.changes).map(([key, value]) => (
                <TypeItem key={key}>
                  <TypeTitle>
                    {intl
                      .formatMessage({
                        id: 'changelog.' + key,
                      })
                      .toUpperCase()}
                  </TypeTitle>
                  {value.length > 0 && (
                    <List>
                      {value.map((subitem, index) => (
                        <TypeItem key={index}>
                          <CategoryTitle>- {subitem.category}</CategoryTitle>
                          <List>
                            {subitem.items.map((item, i) => (
                              <ListItem key={i}>
                                +{' '}
                                {intl.formatMessage({
                                  id: 'changelog.' + log.version + '.' + item,
                                })}{' '}
                              </ListItem>
                            ))}
                          </List>
                        </TypeItem>
                      ))}
                    </List>
                  )}
                </TypeItem>
              ))}
            </List>
          </div>
        ))}
      </ChangelogContainer>
    </BaseLayout>
  );
};

export default ChangelogPage;
