import styled from 'styled-components';
import { font, getSpacing, colors } from 'stylesheet';

export const ChangelogContainer = styled.div`
  padding: ${getSpacing(4)};
`;

export const Title = styled.h2`
  ${font.size24};
  margin-bottom: ${getSpacing(2)};
  color: ${colors.purple};
`;

export const VersionTitle = styled.h3`
  ${font.size20};
  font-weight: bolder;
`;

export const CategoryTitle = styled.h4`
  ${font.size16};
  padding-left: ${getSpacing(4)};
`;

export const TypeTitle = styled.h4`
  ${font.size16};
  padding-left: ${getSpacing(2)};
`;

export const List = styled.ul`
  ul {
    margin-bottom: ${getSpacing(0)};
  }
`;

export const TypeItem = styled.li`
  ${font.size14};
  margin-bottom: ${getSpacing(1)};
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.li`
  margin-bottom: ${getSpacing(1)};
  display: flex;
  padding-left: ${getSpacing(6)};
`;
